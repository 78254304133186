import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=5c1b0a88&scoped=true"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=5c1b0a88&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c1b0a88",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('E:/Jason/project/IShell/code/ishell_os/components/Header.vue').default})
