import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _43d4ce9c = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _2b9aa07c = () => interopDefault(import('..\\pages\\price.vue' /* webpackChunkName: "pages/price" */))
const _4054c0b4 = () => interopDefault(import('..\\pages\\RedeemCode.vue' /* webpackChunkName: "pages/RedeemCode" */))
const _57e874c0 = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _d22fc432 = () => interopDefault(import('..\\pages\\updatePwd.vue' /* webpackChunkName: "pages/updatePwd" */))
const _7a2d2b85 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _43d4ce9c,
    name: "login"
  }, {
    path: "/price",
    component: _2b9aa07c,
    name: "price"
  }, {
    path: "/RedeemCode",
    component: _4054c0b4,
    name: "RedeemCode"
  }, {
    path: "/register",
    component: _57e874c0,
    name: "register"
  }, {
    path: "/updatePwd",
    component: _d22fc432,
    name: "updatePwd"
  }, {
    path: "/",
    component: _7a2d2b85,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
