// plugins/api.js
export default ({ $axios }, inject) => {
    const api = {
        // 获取套餐价格
        getPrice() {
            return $axios.$get('/price');
        },

        // 用户注册
        createUser(data) {
            return $axios.$post('/user/register', data,{
                headers: {
                  'Content-Type': 'application/json'
                }
              });
        },
        // 用户登录
        userLogin(data) {
            return $axios.$post('/user/login', data);
        },
        // 获取邮箱验证码
        getEmailCode(params){
            return $axios.$get('/user/email-code', {params});
        },
        // 重置密码
        updatePwd(data){
            return $axios.$post('/user/update-pwd', data);
        },

        // 下单
        createOrder(priceId){
            return $axios.$post(`/order/create/${priceId}`, null);
        },
        // 查询是否支付成功
        getIsPay(orderId) {
            return $axios.$get(`/order/isPay/${orderId}`);
        },
        // 兑换码 兑换
        redeemCode(code) {
            return $axios.$post(`/order/create/redeem_code/${code}`, null);
        },
    };

    inject('api', api);
};
